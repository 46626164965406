<template>
  <div class="w-100 p-l-30 p-r-30">
    <div class="row m-t-30 m-t-sm-20 col-padding">
      <div class="width-250 daterange">
        <label>期間</label>
        <DatePicker ref="datePicker" :position="'right'" class="mt-1" v-bind:date-range="dateRange"
                    @change-date="updateData"></DatePicker>
      </div>

      <div class="w-auto timerange">
        <label>時間</label>
        <div class="mt-1 d-inline-flex">
          <span class="width-100">
            <vue-timepicker
                v-model="startTime"
                :manual-input-timeout="1500"
                :minute-interval="5"
                advanced-keyboard
                auto-scroll
                drop-direction="auto"
                hide-clear-button
                manual-input
                @change="getData">
            </vue-timepicker>
          </span>
          <span class="separator px-2 mt-1">〜</span>
          <span class="width-100">
            <vue-timepicker
                v-model="endTime"
                :manual-input-timeout="1500"
                :minute-interval="5"
                advanced-keyboard
                auto-scroll
                drop-direction="auto"
                hide-clear-button
                manual-input
                @change="getData">
            </vue-timepicker>
          </span>
          <!--          <input v-model="query.startTime" class="form-control width-120" type="time">-->
          <!--          <span class="separator px-3 mt-1">〜</span>-->
          <!--          <input v-model="query.endTime" @change="getData" class="form-control width-120" type="time">-->
        </div>
      </div>
      <div class="width-110 filtering-column140 query">
        <label>操作</label>
        <select id="operation" v-model="query.operation"
                :required="true"
                class="form-control color-33 mt-1 filter-select select-tournament form-select"
                @change="getData">
          <option :key="Number('0')" :value="Number('0')">すべて</option>
          <option :key="Number('1')" :value="Number('1')">Create</option>
          <option :key="Number('2')" :value="Number('2')">Update</option>
          <option :key="Number('3')" :value="Number('3')">Delete</option>
        </select>
      </div>
      <div class="col col-keyword filtering-column270 search">
        <label for="keyword">キーワード</label>
        <div class="input-group mt-1">
          <input
              id="keyword"
              v-model="query.keyword"
              class="form-control color-33 py-2 border-right-0 bg-transparent filter-search-form"
              placeholder="キーワードを入力"
              type="search"
              @keydown.enter="onKeyDown"
          />
          <span class="input-group-append">
              <button
                  class="input-group-text btn-search border-left-0 bg-transparent p-2"
                  type="submit"
                  @click="search"
              >
                <i class="fa fa-search"></i>
              </button>
            </span>
        </div>
        <div v-show="errors['keyword']" class="form-error">{{ errors['keyword'] }}</div>
      </div>
    </div>
    <div class="datatable">
      <Datatable
          :columns="columns"
          :getData="getData"
          :loading="loading"
          :response="response"
          :sortable="true"
          @cta="cta">
      </Datatable>
    </div>
  </div>
</template>

<script>
import {app, datatable, gender, defaults} from "@/constants"
import Datatable from "../components/datatable/Datatable"
import {mapGetters} from "vuex"
import Common from "../assets/jsadminclient/common"
import Helper from "../assets/jsadminclient/helper"
import DatePicker from "../components/datepicker/DatePicker"
import VueTimepicker from "vue2-timepicker"

export default {
  name: "DBLogs",
  components: {Datatable, DatePicker, VueTimepicker},
  data() {
    return {
      query: {
        startAt: null,
        endAt: null,
        startTime: '00:00',
        endTime: '23:59',
        operation: '0',
        keyword: '',
        currentPage: 1,
        perPage: datatable.default.perPage,
        sort: 'dateTime',
        order: 'desc',
      },
      response: {
        sort: 'dateTime',
        order: 'desc',
        current_page: 1,
        per_page: datatable.default.perPage
      },
      dateRange: {
        startDate: null,
        endDate: null
      },
      startTime: {
        HH: '00',
        mm: '00'
      },
      endTime: {
        HH: '23',
        mm: '59'
      },
      operation: '0',
      loading: true,
      columns: datatable.columns.dblogs,
      errors: []
    }
  },
  computed: {
    ...mapGetters(["config"]),
    // years() {
    //     const year = new Date().getFullYear();
    //     return Array.from(
    //         {length: year - 1919},
    //         (value, index) => 1919 + (index + 1)
    //     )
    // },
  },
  mounted() {
    this.restoreSearchQuery()
    this.initialPageLoadHandler();
    this.updateData(this.dateRange)
  },
  methods: {
    onKeyDown(event) {
      if (event.keyCode !== 13) {
        return
      }
      this.search()
    },
    search() {
      this.errors = []
      this.response.current_page = 1
      this.getData();
    },
    updateData(dateRange) {
      this.dateRange = dateRange
      this.query.startAt = Common.dateFormatConvert(dateRange.startDate)
      this.query.endAt = Common.dateFormatConvert(dateRange.endDate)
      this.getData()
    },
    initialPageLoadHandler() {
      if (this.defaultResponse) {
        this.response = this.defaultResponse
      }
      Object.assign(this.columns[0], {
        format: (dateTime) => {
          return moment(dateTime).format("YYYY/MM/DD  HH:mm")
        },
      });
      Object.assign(this.columns[6], {
        format: (details) => {
          return '<i class="fas fa-info-circle font-16 text-info"></i>';
        },
      });
      // this.getRoles()
    },
    async getData() {
      try {
        this.loading = true;

        const startTimeString = this.startTime.HH + ':' + this.startTime.mm
        const endTimeString = this.endTime.HH + ':' + this.endTime.mm

        var api = new AdminApi.JTALogApi(Helper.apiConfig(this.config.token));
        var result = await api.jtaLogsGetAsync(
            this.dateRange.startDate,
            this.dateRange.endDate,
            startTimeString,
            endTimeString,
            this.query.keyword,
            this.query.operation,
            this.response.sort,
            this.response.order,
            this.response.current_page,
            this.response.per_page
        )

        if (result) {
          this.loading = false
          // this.reloadKey += 1
          this.response = Helper.formatResponse(result)
          // map user data
          this.response.data = result.items.map(function (item) {
            return {
              logId: item.logId,
              operation: item.operation,
              dateTime: item.dateTime,
              lastName: item.lastName,
              firstName: item.firstName,
              userId: item.userId,
              target: item.target
            }
          })
        }

        this.query.startAt = Common.dateFormatConvert(this.dateRange.startDate)
        this.query.endAt = Common.dateFormatConvert(this.dateRange.endDate)
        this.query.startTime = startTimeString
        this.query.endTime = endTimeString
        this.query.currentPage = this.response.current_page
        this.query.perPage = this.response.per_page
        this.query.sort = this.response.sort
        this.query.order = this.response.order

        Common.updateCurrentPageQuery(this, this.createSearchQuery())
      } catch (e) {
        this.response.data = [];
        this.loading = false;
        this.errors = Common.getErrorList(e.response, this.errors)

        const isTokenError = Common.isTokenError(e.response)
        if (isTokenError) {
          this.$router.push({name: 'Login'});
        } else {
          Common.handleAPIError(e)
        }
      }
    },
    cta(key, row) {
      const logId = row[key];
      this.$router.push({
        name: 'DBLogDetail',
        params: {
          logId: logId,
          defaultResponse: this.response
        },
        query: this.query
      })
    },
    createSearchQuery(orgQuery = null) {
      return Common.appendSearchQuery(
          orgQuery,
          Common.appendSearchQuery(this.query, {}))
    },
    restoreSearchQuery() {
      Common.restoreQuery(this.query, 'operation', this.$route.query.operation)
      Common.restoreQuery(this.query, 'keyword', this.$route.query.keyword)

      if (this.$route.query.startAt && this.$route.query.endAt) {
        Common.restoreQuery(this.dateRange, 'startDate', this.$route.query.startAt)
        Common.restoreQuery(this.dateRange, 'endDate', this.$route.query.endAt)
        this.$refs.datePicker.setDateRange(this.dateRange, false)
      } else {
        this.$refs.datePicker.setTodayAsDefaultSelection(false)
      }

      if (this.$route.query.startTime) {
        const splitStartTime = this.$route.query.startTime.split(':')
        if (splitStartTime.length === 2) {
          Common.restoreQuery(this.startTime, 'HH', splitStartTime[0])
          Common.restoreQuery(this.startTime, 'mm', splitStartTime[1])
        }
      }
      if (this.$route.query.endTime) {
        const splitEndTime = this.$route.query.endTime.split(':')
        if (splitEndTime.length === 2) {
          Common.restoreQuery(this.endTime, 'HH', splitEndTime[0])
          Common.restoreQuery(this.endTime, 'mm', splitEndTime[1])
        }
      }

      Common.restoreQuery(this.response, 'sort', this.$route.query.sort)
      Common.restoreQuery(this.response, 'order', this.$route.query.order)
      Common.restoreQuery(this.response, 'per_page', this.$route.query.perPage)
      Common.restoreQuery(this.response, 'current_page', this.$route.query.currentPage)
    },
  }
}
</script>

<style lang="scss" scoped>

.col {
  &-keyword {
    .btn-search {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }

    @media only screen and (max-width: 991px) {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 258px;
      margin-top: 20px;
    }
  }
}

@media only screen and (max-width: 991px) {
  .m-t-sm-20 {
    margin-top: 20px !important;
  }
}

table tr {
  th, td {
    &:nth-child(1) {
      width: 200px;
    }

    &:nth-child(2) {
      width: 100px;
    }

    &:nth-child(3) {
      width: 130px;
    }

    @media only screen and (max-width: 1000px) {
      padding: 10px;
      &:nth-child(2), &:nth-child(3) {
        width: 100px;
      }
    }
    @media only screen and (max-width: 900px) {
      padding: 8px;
      &:nth-child(2), &:nth-child(3) {
        width: 80px;
      }
    }
    @media only screen and (max-width: 767px) {
      padding: 6px;
      &:nth-child(2), &:nth-child(3) {
        width: 70px;
      }
    }
  }
}

.custom-button {
  background: #C95D6C;
  color: #FFFFFF;
  border-radius: 4px;
  width: 160px;
}

.tournament-search-button {
  height: 48px;
  background: #C95D6C;
  border-radius: 4px;
  color: #FFFFFF;
  width: 100%;
}

input[type=time] {
  height: 32px !important;
}

#operation {
  height: 32px !important;
}

.filter-search-form {
  height: 32px !important;
}

a {
  color: #333333;
}

::placeholder {
  color: #333333;
}

.tournamentId_box {
  width: calc(100% - 160px);
}

.dob-section {
  .parent-block {
    .child-block {
      position: absolute;
      top: 0px;
      background: #F8F8F8;
      justify-content: center;
      align-items: center;
      display: flex;
      border: 1px solid #F0E7ED;
      border-radius: 0px 4px 4px 0px;
      height: 100%;
      max-height: 48px;
      width: 2.25rem;
      z-index: 9999;
      right: 0;
      color: #888888;
      border-top-right-radius: 4px !important;
      border-bottom-right-radius: 4px !important;
    }
  }

  .form-control {
    border-top-left-radius: 4px !important;
    border-bottom-left-radius: 4px !important;
  }
}

.custom-select-year {
  width: 28%;
  margin-right: 23px;

  &:first-child {
    width: 40%;
  }

  &:last-child {
    width: 28%;
    margin-right: 0px !important;
  }
}

@media only screen and (max-width: 768px) {
  .daterange {
    width: 38%;
  }
  .timerange {
    width: 43%;
  }
  .separator {
    padding-right: 6px !important;
    padding-left: 6px !important;
  }
  .query {
    width: 19%;
  }
  .search {
    margin-top: 10px;
    max-width: 300px;
  }
}

</style>
